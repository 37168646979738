@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter-Regular.ttf');
}

body {
  margin: 0;
  font-family: Inter;
  font-style: 'normal';
  text-align: 'left';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
